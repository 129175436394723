import {
  CreateButton,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd"
import { BaseRecord, IResourceComponentsProps } from "@refinedev/core"
import { Space, Table, Tag } from "antd"
import React from "react"
import { Order } from "../../entities/Order"
import { SearchForm, onSearch } from "../search-form/SearchForm"
import dayjs from "dayjs"
import { useTableColumnOnRowNavigateToShow } from "../../helpers/hooks"

export const OrdersList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch,
  })
  
  const navigateToShow = useTableColumnOnRowNavigateToShow()

  return (
    <List
      headerButtons={[<SearchForm {...searchFormProps} />, <CreateButton />]}
    >
      <Table {...tableProps} rowKey='id' tableLayout='auto' onRow={navigateToShow} >
        <Table.Column dataIndex='code' title='Código' ellipsis/>
        <Table.Column
          dataIndex={["product", "name"]}
          title='Produto'
          ellipsis
        />
        <Table.Column
          dataIndex={["customer", "companyName"]}
          title='Cliente'
          ellipsis
        />
        <Table.Column
          dataIndex={["status", "name"]}
          title='Status'
          render={(value: string, record: Order) => (
            <Tag
              color={
                record.status.production
                  ? record.productionStatus.color
                  : record.status.color
              }
              style={{
                width: "100%",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              {record.status.production
                ? `${record.status.name} - ${record.productionStatus.name}`
                : `${record.status.name}`}
            </Tag>
          )}
        />
        <Table.Column
          dataIndex={"targetDate"}
          title='Data de Entrega'
          render={(value) => dayjs(value).format("DD/MMM").toUpperCase()}
        />
        <Table.Column
          title='Ações'
          dataIndex='actions'
          width='5rem'
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
              <DeleteButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
