import { EditButton, ListButton, RefreshButton, Show } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import React from "react"
import { useShowForm } from "../../helpers/useShowForm"
import { OrdersForm } from "./OrdersForm"
import { Order } from "../../entities/Order"
import { advanceOrder } from "../../service/orders"
import { ArrowRightOutlined } from "@ant-design/icons"
import { ActionButton } from "../../helpers/buttons"
import { Popconfirm } from "antd"

export const OrdersShow: React.FC<IResourceComponentsProps> = () => {
  const { formProps, formLoading, refetch } = useShowForm<Order>()

  const ordersData = formProps.initialValues as Order

  const handleAdvance = async () => {
    if (ordersData) {
      await advanceOrder(ordersData)
      refetch()
    }
  }

  const AdvanceButton = (
    <Popconfirm
      title='Confirmar avanço?'
      description='Tem certeza que deseja avançar este pedido de status/fase?'
      onConfirm={handleAdvance}
      disabled={!ordersData?.canAdvance}
    >
      <ActionButton
        icon={<ArrowRightOutlined />}
        type='primary'
        resource='orders'
        action='advance'
        disabled={!ordersData?.canAdvance}
      >
        Avançar
      </ActionButton>
    </Popconfirm>
  )

  return (
    <Show
      isLoading={formLoading}
      headerButtons={[
        <ListButton />,
        <EditButton />,
        AdvanceButton,
        <RefreshButton />,
      ]}
    >
      <OrdersForm formProps={formProps} disabled />
    </Show>
  )
}
