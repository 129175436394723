import React from "react"
import {
  TitleProps,
  useRouterContext,
  useRouterType,
  useLink,
} from "@refinedev/core"

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const routerType = useRouterType()
  const Link = useLink()
  const { Link: LegacyLink } = useRouterContext()

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link

  return (
    <ActiveLink to='/'>
      {collapsed ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src='/img/logo_small.png'
            alt='TCH Guindastes'
            style={{
              margin: "0 auto",
              padding: "12px 0",
              maxHeight: "55.5px",
            }}
          />
        </div>
      ) : (
        <img
          src='/img/logo.png'
          alt='TCH Guindastes'
          style={{
            width: "200px",
            padding: "12px 24px 0 0",
          }}
        />
      )}
    </ActiveLink>
  )
}
