import {
  BooleanField,
  CreateButton,
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd"
import { BaseRecord, IResourceComponentsProps } from "@refinedev/core"
import { Space, Table } from "antd"
import React from "react"
import { Customer } from "../../../entities/Customer"
import { renderCnpjCpf, renderPhoneNumber } from "../../../helpers/formatters"
import { onSearch, SearchForm } from "../../search-form/SearchForm"
import { useTableColumnOnRowNavigateToShow } from "../../../helpers/hooks"

export const CustomersList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<Customer, any, string>({
    syncWithLocation: true,
    onSearch,
    sorters: {
      initial: [{ field: "companyName", order: "asc" }],
    },
  })

  const navigateToShow = useTableColumnOnRowNavigateToShow()
  
  return (
    <>
      <List
        headerButtonProps={{ size: "large" }}
        headerButtons={[<SearchForm {...searchFormProps} />, <CreateButton />]}
      >
        <Table {...tableProps} rowKey='id' onRow={navigateToShow}>
          <Table.Column dataIndex='id' title='Id' sorter />
          <Table.Column dataIndex='companyName' title='Razão Social' sorter />
          <Table.Column
            dataIndex='cnpjCpf'
            title='Cnpj/Cpf'
            render={renderCnpjCpf}
            width='10rem'
            sorter
          />
          <Table.Column
            dataIndex='contactName'
            title='Contato'
            responsive={["lg"]}
            sorter
          />
          <Table.Column
            dataIndex='phoneNumber'
            title='Telefonne'
            render={renderPhoneNumber}
            responsive={["lg"]}
          />
          <Table.Column
            dataIndex='complete'
            title='Completo'
            render={(value) => <BooleanField value={value} />}
            responsive={["lg"]}
          />
          <Table.Column
            title='Actions'
            dataIndex='actions'
            width='10rem'
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton hideText size='small' recordItemId={record.id} />
                <ShowButton hideText size='small' recordItemId={record.id} />
                <DeleteButton hideText size='small' recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  )
}
