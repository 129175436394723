import { useSelect } from "@refinedev/antd"
import { Form, FormProps, Input, Select } from "antd"
import React from "react"
import { Accessory } from "../../entities/Accessory"
import { Vendor } from "../../entities/Vendor"
import { AuditPanel } from "../audit-panel/AuditPanel"
import { RowSpacer } from "../row-spacer/RowSpacer"

type Props = {
  formProps: FormProps<Accessory>
  disabled?: boolean
}

export const ComponentsGroupsForm: React.FC<Props> = ({
  formProps,
  disabled,
}) => {
  const { selectProps: vendorsSelectProps } = useSelect<Vendor>({
    resource: "vendors",
    optionValue: "id",
    optionLabel: "companyName",
    defaultValue: formProps.initialValues?.vendors.map((vendor) => vendor.id),
    onSearch: (search: string) => [
      {
        field: "search",
        operator: "contains",
        value: search,
      },
    ],
    sorters: [
      {
        field: "companyName",
        order: "asc"
      }
    ]
  })
  return (
    <Form {...formProps} layout='vertical' disabled={disabled}>
      <RowSpacer spans={[4, 20, 24]}>
        <Form.Item label='Id' name={["id"]}>
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label='Name'
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input autoFocus style={{ textTransform: "uppercase" }} />
        </Form.Item>
        <Form.Item
          label='Fornecedores'
          name={"vendors"}
          getValueProps={(value: any[]) => {
            return {
              value: value?.map((item) => item?.id),
            }
          }}
          getValueFromEvent={(selected: string[]) => {
            return selected?.map((item) => ({ id: item }))
          }}
        >
          <Select showSearch={true} mode='multiple' {...vendorsSelectProps} />
        </Form.Item>
      </RowSpacer>
      <RowSpacer>
        {formProps.initialValues && (
          <AuditPanel
            tableName='componentsGroups'
            pk={formProps.initialValues.id}
          />
        )}
      </RowSpacer>
    </Form>
  )
}
