import { EditButton, List, ShowButton, useTable } from "@refinedev/antd"
import { BaseRecord, IResourceComponentsProps } from "@refinedev/core"
import { Space, Table, Tag } from "antd"
import React from "react"
import { useTableColumnOnRowNavigateToShow } from "../../helpers/hooks"

export const OrdersStatusList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  })

  const navigateToShow = useTableColumnOnRowNavigateToShow()

  return (
    <List canCreate={false}>
      <Table {...tableProps} rowKey='id' onRow={navigateToShow}>
        <Table.Column dataIndex='id' title='Id' />
        <Table.Column dataIndex='sequence' title='Ordem' />
        <Table.Column dataIndex='name' title='Nome' />
        <Table.Column dataIndex='defaultDeadline' title='Prazo Padrão (dias)' />
        <Table.Column
          dataIndex='color'
          title='Cor'
          render={(value) => (
            <Tag
              color={value}
              style={{ minWidth: "18ch", textAlign: "center" }}
            >
              {value}
            </Tag>
          )}
        />
        <Table.Column
          title='Ações'
          dataIndex='actions'
          width='5rem'
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size='small' recordItemId={record.id} />
              <ShowButton hideText size='small' recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
