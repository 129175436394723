import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FastForwardOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons"
import { useSelect } from "@refinedev/antd"
import { Button, Popconfirm, Space, StepProps, Typography } from "antd"
import dayjs from "dayjs"
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { Component } from "../entities/Component"
import { Customer } from "../entities/Customer"
import { Order, OrderTimelineEntry } from "../entities/Order"
import { OrderStatus } from "../entities/OrderStatus"
import { Product } from "../entities/Product"
import { ProductionStatus } from "../entities/ProductionStatus"
import { useSearchParams } from "react-router-dom"
import { useResource, useNavigation } from "@refinedev/core"

export const useTimelineSteps = (
  order?: Order,
  ordersStatuses?: OrderStatus[],
  productionStatuses?: ProductionStatus[]
) =>
  useMemo<StepProps[]>(() => {
    if (!order?.timeline || !ordersStatuses || !productionStatuses) return []

    const lastEntry = order.timeline.sort(
      (a: OrderTimelineEntry, z: OrderTimelineEntry) =>
        a.status.sequence - z.status.sequence
    )[order.timeline.length - 1]

    let entries: {
      timelineEntries: OrderTimelineEntry[]
      orderStatus: OrderStatus
    }[] = []

    ordersStatuses
      .sort((a: OrderStatus, z: OrderStatus) => a.sequence - z.sequence)
      .forEach((orderStatus) => {
        const timelineEntries = order.timeline.filter(
          (entry: OrderTimelineEntry) => entry.status.id === orderStatus.id
        )

        entries.push({ timelineEntries, orderStatus })
      }, [])

    return entries.map(({ timelineEntries, orderStatus }) => {
      const lastTimelineEntry = timelineEntries[timelineEntries.length - 1]
      const hasManyEntries = orderStatus.production

      const passed =
        orderStatus && (orderStatus.sequence || -1) < lastEntry.status.sequence

      return {
        title: (
          <span
            style={{
              color: passed
                ? "inherit"
                : lastTimelineEntry
                ? orderStatus.color
                : "silver",
              fontWeight: "bold",
            }}
          >
            {orderStatus.name}{" "}
          </span>
        ),
        description: timelineEntries.map((timelineEntry) =>
          timelineEntry.skipped ? (
            <div>Não registrado</div>
          ) : (
            <div>
              {hasManyEntries ? (
                <Typography.Text strong style={{ paddingLeft: "1ch" }}>
                  {timelineEntry.productionStatus?.name}
                </Typography.Text>
              ) : (
                ""
              )}
              <div style={{ paddingLeft: hasManyEntries ? "2ch" : "inherit" }}>
                <div>
                  em{" "}
                  {dayjs(timelineEntry.createdAt).format(
                    "DD/MM/YYYY [às] HH:mm"
                  )}
                </div>
                <div>por {timelineEntry.enteredBy?.firstName}</div>
              </div>
            </div>
          )
        ),
        status: lastTimelineEntry ? (passed ? "finish" : "process") : "wait",
        icon:
          lastTimelineEntry && lastTimelineEntry.skipped ? (
            <FastForwardOutlined />
          ) : undefined,
      }
    })
  }, [order?.timeline, ordersStatuses, productionStatuses])

export const useCustomerSelect = () => {
  const { selectProps } = useSelect<Customer>({
    resource: "customers",
    optionValue: "id",
    optionLabel: "companyName",
    onSearch: (search: string) => [
      {
        field: "search",
        operator: "contains",
        value: search,
      }
    ],
    sorters: [
      {
        field: "companyName",
        order: "asc"
      }
    ]
  })

  return selectProps
}

export const useProductSelect = () => {
  const { selectProps } = useSelect<Product>({
    resource: "products",
    optionValue: "id",
    optionLabel: "name",
    onSearch: (search: string) => [
      {
        field: "search",
        operator: "contains",
        value: search,
      },
    ],
    sorters: [
      {
        field: "name",
        order: "asc"
      }
    ]
  })

  return selectProps
}

export const useStatusSelect = () => {
  const { selectProps } = useSelect<Product>({
    resource: "ordersStatuses",
    optionValue: "id",
    optionLabel: "name",
    onSearch: (search: string) => [
      {
        field: "search",
        operator: "contains",
        value: search,
      },
    ],
  })

  return selectProps
}

export const useProductColorSelect = () => {
  const { selectProps } = useSelect<Product>({
    resource: "productsColors",
    optionValue: "id",
    optionLabel: "name",
    onSearch: (search: string) => [
      {
        field: "search",
        operator: "contains",
        value: search,
      },
    ],
    sorters: [
      {
        field: "name",
        order: "asc"
      }
    ]
  })

  return selectProps
}

export const useComponentSelect = () => {
  const { selectProps } = useSelect<Component>({
    resource: "components",
    optionValue: "id",
    optionLabel: "description",
    onSearch: (search: string) => [
      {
        field: "search",
        operator: "contains",
        value: search,
      },
      
    ],
    sorters: [
      {
        field: "description",
        order: "asc"
      }
    ]
  })

  return selectProps
}

type UseEditingTableProps<T> = {
  disabled?: boolean
  onAddItem: () => { index: number; item: T }
  onUpdateItem: (item: T, index: number) => void
  onDeleteItem: (index: number) => void
  onValidateItem: (item: T) => boolean
}

export const useEditingTable = <T extends {}>({
  disabled,
  onAddItem,
  onUpdateItem,
  onDeleteItem,
  onValidateItem,
}: UseEditingTableProps<T>) => {
  const [editingIndex, setEditingIndex] = useState<number | undefined>()
  const [editingItem, setEditingItem] = useState<T | undefined>()
  const [isInserting, setIsInserting] = useState(false)

  const isEditingNow = editingIndex !== undefined && !isNaN(editingIndex)

  const isEditing = useCallback(
    (index: number) => {
      return editingIndex === index
    },
    [editingIndex]
  )

  const editItem = useCallback(
    (index: number, item: T, isInserting: boolean = false) => {
      if (!item) return

      setEditingIndex(index)
      setEditingItem(item)
      setIsInserting(isInserting)
    },
    []
  )

  const updateEditingItem = (item: Partial<T>) => {
    setEditingItem({ ...editingItem, ...(item as T) })
  }

  const addItem = useCallback(() => {
    const { index, item } = onAddItem()

    if (item) {
      editItem(index, item, true)
    }
  }, [editItem, onAddItem])

  const cancelEdit = useCallback(
    (removeIfInserting: boolean = false) => {
      if (isInserting && removeIfInserting) {
        onDeleteItem(editingIndex!)
      }
      setEditingIndex(undefined)
      setEditingItem(undefined)
    },
    [editingIndex, isInserting, onDeleteItem]
  )

  const saveEdit = useCallback(() => {
    if (!editingItem || !isEditingNow) return

    if (!onValidateItem(editingItem)) return

    onUpdateItem(editingItem, editingIndex)
    cancelEdit()
  }, [
    cancelEdit,
    editingIndex,
    editingItem,
    isEditingNow,
    onUpdateItem,
    onValidateItem,
  ])

  useEffect(() => {
    if (editingIndex === undefined) setIsInserting(false)
  }, [editingIndex])

  const renderTableButtons = useCallback(
    () => [
      <Button
        icon={<PlusCircleOutlined />}
        disabled={disabled || isEditingNow}
        onClick={() => addItem()}
      />,
    ],
    [addItem, disabled, isEditingNow]
  )

  const renderRowButtons = useCallback(
    (canEdit: boolean = true) =>
      (value, record, index) => {
        const spaced = (elements: ReactNode) => (
          <Space size={"small"}>{elements}</Space>
        )
        const deleteButton = (
          <Popconfirm
            title='Excluir este registro?'
            onConfirm={() => onDeleteItem(index)}
          >
            <Button
              icon={<DeleteOutlined />}
              danger
              disabled={disabled || isEditing(index)}
            />
          </Popconfirm>
        )

        if (!isEditing(index)) {
          if (!canEdit) {
            return spaced(deleteButton)
          }

          return spaced(
            <>
              <Button
                icon={<EditOutlined />}
                onClick={() => editItem(index, record)}
                disabled={disabled || isEditing(index)}
              />
              {deleteButton}
            </>
          )
        }

        return spaced(
          <>
            <Button
              icon={<CheckOutlined />}
              type='primary'
              onClick={saveEdit}
              disabled={disabled}
            />
            <Button icon={<CloseOutlined />} onClick={() => cancelEdit(true)} />
          </>
        )
      },
    [disabled, isEditing, saveEdit, onDeleteItem, editItem, cancelEdit]
  )

  return {
    isEditing,
    updateEditingItem,
    editingItem,
    renderTableButtons,
    renderRowButtons,
  }
}

type UseQueryParamReturn = [string | undefined, (value: string) => void]

export const useQueryParam = (
  key: string,
  defaultValue?: string
): UseQueryParamReturn => {
  const [searchParams, setSearchParams] = useSearchParams()

  const setValue = useCallback(
    (value: string) => {
      searchParams.set(key, value)
      setSearchParams(searchParams)
    },
    [key, searchParams, setSearchParams]
  )

  const value = searchParams.get(key) || defaultValue

  return [value, setValue]
}

export const useTableColumnOnRowNavigateToShow = () => {
  const { resource } = useResource()
  const { show } = useNavigation()

  return (record) => ({
    onClick: () => resource && show(resource, record.id),
    style: { cursor: "pointer" }
  })
}
