import { DownloadOutlined } from "@ant-design/icons"
import { DeleteButton, EditButton, List, useTable } from "@refinedev/antd"
import { IResourceComponentsProps } from "@refinedev/core"
import { Space, Table, Tag } from "antd"
import dayjs from "dayjs"
import React from "react"
import { PurchaseQuotation } from "../../entities/PurchaseQuotation"
import { downloadPurchaseQuotationsReport } from "../../service/reports"
import { SearchForm, onSearch } from "../search-form/SearchForm"
import { StatusTargetTag } from "../../helpers/StatusTargetTag"
import { ActionButton } from "../../helpers/buttons"
import { useTableColumnOnRowNavigateToShow } from "../../helpers/hooks"

export const PurchaseQuotationsList: React.FC<
  IResourceComponentsProps
> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch,
    sorters: {
      initial: [{ field: "id", order: "desc" }],
    },
  })

  const handleDownload = (id?: number) => {
    if (!id) return
    downloadPurchaseQuotationsReport(id)
  }

  const navigateToShow = useTableColumnOnRowNavigateToShow()

  return (
    <List headerButtons={[<SearchForm {...searchFormProps} />]}>
      <Table {...tableProps} rowKey='id' onRow={navigateToShow}>
        <Table.Column dataIndex='id' title='Id' sorter />
        <Table.Column<PurchaseQuotation>
          dataIndex={["order", "code"]}
          title='Pedido'
          defaultSortOrder={"descend"}
          render={(value, record) => (
            <Space direction='vertical'>
              {`#${record.order.code}`}
              <Tag color={record.order.status.color}>
                {record.order.status.name}
              </Tag>
            </Space>
          )}
        />
        <Table.Column
          dataIndex={["vendor", "companyName"]}
          title='Fornecedor'
        />
        <Table.Column<PurchaseQuotation>
          dataIndex={["order", "product", "name"]}
          title='Produto'
          render={(value, record) => (
            <Space direction='vertical'>
              {record.order.product.name}
              {`NS: ${record.order.serialNumber}`}
            </Space>
          )}
        />
        <Table.Column
          dataIndex={["order", "customer", "companyName"]}
          title='Cliente'
        />
        <Table.Column<PurchaseQuotation>
          dataIndex={["order", "statusTargetDiff"]}
          title='Prazo'
          render={(value, record) => <StatusTargetTag order={record.order} />}
        />
        <Table.Column
          dataIndex={"updatedAt"}
          title='Atualizado em'
          sorter
          render={(value) =>
            dayjs(value).format("DD/MM/YYYY HH:mm").toUpperCase()
          }
        />
        <Table.Column
          title='Ações'
          dataIndex='actions'
          width='5rem'
          render={(_, record: PurchaseQuotation) => (
            <Space>
              <EditButton size='small' hideText recordItemId={record.id} />
              <DeleteButton size='small' hideText recordItemId={record.id} />
              <ActionButton
                icon={<DownloadOutlined />}
                size='small'
                resource='purchaseQuotations'
                action='download'
                onClick={() => handleDownload(record?.id)}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
